import { useEffect } from 'react'

export default function Impressum() {
  useEffect(() => {
    const st = document.createElement('script')
    if (process.env.NODE_ENV === 'production') {
      st.src = '//admin.fogyasztobarat.hu/e-api.js'
    }
    st.type = 'text/javascript'
    st.setAttribute('data-id', 'NVDCC4I1')
    st.setAttribute('id', 'fbarat-embed')
    st.setAttribute('data-type', 'ws_imp')
    const s = document.getElementById('barat_script')
    s!.parentNode!.insertBefore(st, s)

    return () => { st.remove() }
  }, [])

  return <div className="p-20"><span id="barat_script" className="hidden"></span></div>
}
