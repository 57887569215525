import { animated, useSpringRef, useTransition } from '@react-spring/web'
import TestimonialCarouselItem from './TestimonialCarouselItem'
import { useEffect, useState } from 'react'

interface Testimonial {
  text: string
  userInfo: string
  image: string
}

interface TestimonialsProps {
  testimonials: Testimonial[]
  slideDuration: number
}

export default function TestimonialCarousel(props: Readonly<TestimonialsProps>) {
  const [index, setIndex] = useState(0)

  const transRef = useSpringRef()

  const transitions = useTransition(index, {
    ref: transRef,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  })

  useEffect(() => {
    transRef.start()
    const timer = setInterval(() => {
      setIndex((index + 1) % props.testimonials.length)
    }, props.slideDuration)
    return () => {
      window.clearInterval(timer)
    }
  }, [index, setIndex])

  return (
    <div className="overflow-hidden relative min-w-full h-38rem">
      {transitions((style, i) => (
        <animated.div className="absolute w-full flex items-center justify-center" style={{...style}}>
          <TestimonialCarouselItem
            key={`feature-${i}`}
            text={props.testimonials[i].text}
            userInfo={props.testimonials[i].userInfo}
            image={props.testimonials[i].image}
          />
        </animated.div>
      ))}
    </div>
  )
}
