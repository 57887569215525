import { Editor } from '@tinymce/tinymce-react'
import Resizer from 'react-image-file-resizer'
import 'utf8'
import utf8 from 'utf8'

interface DescriptionProps {
  title: string
  name: string
  form: any
  initialValue: string
  imagesUploadUrl: string
}

export default function Description(props: Readonly<DescriptionProps>) {
  const handleImageUpload = async (blobInfo: any) => {
    // Resize the image
    const resizedImage = await new Promise<Blob>((resolve) => {
      Resizer.imageFileResizer(
        blobInfo.blob(), 300, 300, 'JPEG', 100, 0,
        (blob) => { resolve(blob as Blob) },
        'blob'
      )
    })

    // Get the image upload details
    const imageUploadDetails = await fetch(props.imagesUploadUrl)
      .then(response => response.json())

    // Upload the image
    const form = new FormData()
    for (let key in imageUploadDetails.fields) {
      form.append(key, imageUploadDetails.fields[key])
    }
    form.append('file', resizedImage)
    await fetch(
      imageUploadDetails.url, {
        method: 'POST',
        body: form
      }
    )

    // Return the URL where the image is accessible
    return `${imageUploadDetails.url}${imageUploadDetails.fields.key}`
  }

  return (
    <>
      <h3 className="mb-5 text-lg font-medium text-gray-900">
        {props.title}
      </h3>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={props.initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'preview', 'anchor', 'searchreplace', 'visualblocks',
            'code', 'fullscreen', 'insertdatetime', 'media', 'table',
            'help', 'wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          images_upload_handler: handleImageUpload,
          statusbar: false
        }}
        onEditorChange={(value) => {
          props.form.handleChange({target: { name: props.name, value: value }})
        }}
      />
      { props.form.errors[props.name] ? <div className="text-red-500 m-3">{props.form.errors[props.name]}</div> : null }
    </>
  )
}
