import './App.css'
import webdesign from './webdesign.jpg'
import webdesign2 from './webdesign-2.jpg'
import webdesign3 from './webdesign-3.jpg'
import gabor from './gabor.jpeg'
import laszlo from './laszlo.jpeg'
import julia from './julia.jpeg'
import { Link, Outlet } from 'react-router-dom'
import MessageForm from './components/MessageForm'
import TestimonialCarousel from './components/TestimonialCarousel'
import FeatureCarousel from './components/FeatureCarousel'

export default function Home() {
  return (
    <>
      <div>
        <section
          className="relative isolate min-h-fill flex items-center justify-center bg-auto lg:bg-cover bg-no-repeat"
          style={{backgroundImage: `url(${webdesign})`}}
        >
          <div className="mx-auto max-w-2xl py-32">
            <div className="text-center">
              <h1 className="text-white text-5xl md:text-6xl select-none font-bold tracking-tight drop-shadow-[-4px_8px_rgba(0,0,0,0.8)]">
                Ha az álmaidról van szó,&nbsp;
                <div className="underline decoration-pink-500 decoration-8 hover:no-underline group transition duration-300 inline-block">
                  NE<span className="-mt-1 block max-w-0 group-hover:max-w-full transition-all duration-500 h-2 bg-indigo-600"></span>
                </div> köss kompromisszumot.
              </h1>
              <div className="mt-20 flex items-center justify-center gap-x-6">
                <a href="#webdesign" className="animate-bounce rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <span className="text-white drop-shadow-[0_2px_rgba(0,0,0,0.8)]">Vágjunk bele!</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="webdesign" className="relative isolate py-8">
          <div className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}
            ></div>
          </div>
          <div>
            <div className="flex justify-center items-center">
              <h2 className="m-6 text-4xl tracking-tight font-extrabold text-gray-900 group transition duration-300 underline underline-offset-8 decoration-pink-500 decoration-4 hover:no-underline w-fit">
                Webdizájn<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
              </h2>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 md:mx-0 md:max-w-none md:grid-cols-2">
              <div className="flex flex-col justify-around">
                <FeatureCarousel
                  features={['Webshop?', 'E-vite?', 'Blog?', 'Üdvözlőoldal?']}
                  constantText="Vállaljuk."
                  slideDuration={3000}
                />
                <div className="p-4 mt-12 flex flex-col items-center justify-around">
                  <div className="flex items-center justify-center">
                    <Link
                      to="/design"
                      className="animate-bounce text-white rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <span className="drop-shadow-[0_2px_rgba(0,0,0,0.8)]">Árajánlatot kérek</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="flex items-center justify-center">
                  <img className="rounded-lg shadow-md max-h-96" src={webdesign2} alt="webdesign" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="webhosting" className="py-8">
          <div className="flex justify-center items-center">
            <h2 className="m-6 text-4xl tracking-tight font-extrabold text-gray-900 group transition duration-300 underline underline-offset-8 decoration-pink-500 decoration-4 hover:no-underline w-fit">
              Webhoszting<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
            </h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 md:mx-0 md:max-w-none md:grid-cols-2">
            <div className="p-4">
              <div className="flex items-center justify-center">
                  <img className="rounded-lg shadow-md max-h-96" src={webdesign3} alt="webhosting" />
              </div>
            </div>
            <div className="flex flex-col justify-around">
              <FeatureCarousel
                features={['Megbízhatóság?', 'Biztonság?', 'Sebesség?', 'A világ bármely pontján?']}
                constantText="Vállaljuk."
                slideDuration={3000}
              />
              <div className="p-4 mt-12 flex flex-col items-center justify-around">
                <div className="flex items-center justify-center">
                  <Link
                    to="/hosting"
                    className="animate-bounce text-white rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <span className="drop-shadow-[0_2px_rgba(0,0,0,0.8)]">Árajánlatot kérek</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-16 shadow-2xl sm:px-16 md:py-24 lg:px-24">
            <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
              <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset="1" stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>

            <div className="flex justify-center items-center">
              <h2 className="m-6 text-4xl tracking-tight font-extrabold text-white group transition duration-300 underline underline-offset-8 decoration-pink-500 decoration-4 hover:no-underline w-fit">
                Rólunk mondták<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600" />
              </h2>
            </div>

            <TestimonialCarousel
              testimonials={[
                {
                  text: 'Eleinte kicsit féltem, mert azt hittem, hogy egy weblap az egy ilyen nagyon bonyolult dolog, de végül meglepően flottul ment minden.',
                  userInfo: 'Gábor, 49',
                  image: gabor
                },
                {
                  text: 'Eredetileg csak a webshopunknak akartunk egy faceliftet, de olyan kedvező ajánlatot kaptunk, hogy már a hosztolást is itt oldjuk meg.',
                  userInfo: 'Júlia, 26',
                  image: julia
                },
                {
                  text: 'Félúton volt egy komolyabb koncepcióváltás az eredeti elképzeléseinkhez képest, de sikerült lekommunikálni, és nagyon gyorsan regáltak rá.',
                  userInfo: 'László, 27',
                  image: laszlo
                }
              ]}
              slideDuration={5000}
            />
          </div>

        </section>

        <section id="contact" className="pt-8 pb-36">

          <div className="flex justify-center items-center">
            <h2 className="m-6 text-4xl tracking-tight font-extrabold text-gray-900 group transition duration-300 underline underline-offset-8 decoration-pink-500 decoration-4 hover:no-underline w-fit">
              Kapcsolat<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
            </h2>
          </div>

          <p className="text-center">Kérdésed van? Kérdezz bátran!</p>

          <MessageForm />

        </section>
      </div>
      <Outlet />
    </>
  )
}
