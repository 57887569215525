import { animated, useSpring } from '@react-spring/web'
import { ReactNode } from 'react'

interface FadeInProps {
  animationDuration: number
  children: ReactNode | ReactNode[]
}

export default function FadeIn(props: Readonly<FadeInProps>) {
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: props.animationDuration
    }
  })

  return (
    <animated.div style={style}>
      {props.children}
    </animated.div>
  )
}
