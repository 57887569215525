import { useEffect, useState } from 'react'
import Button from './components/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import SelectList from './components/SelectList'
import SelectListItem from './components/SelectListItem'
import WebsiteCategory from './components/WebsiteCategory'
import Description from './components/Description'
import Contact from './components/Contact'
import ColorPicker from './components/ColorPicker'
import { useNavigate } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'
import FadeIn from './components/FadeIn'

export default function DesignForm() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [hasColorPalette, setHasColorPalette] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()

  const displayErrorMessage = (message: string) => {
    setErrorMessage(message)
    setShowErrorMessage(true)
  }

  const fieldNameToFieldDisplayName = new Map([
    ['emailAddress', 'Email'],
    ['newWebsite', 'Új weboldalt szeretnél készíteni vagy meglévő weboldalad szeretnéd felújítani?'],
    ['existingWebsiteUrl', 'Meglévő weboldal címe'],
    ['category', 'Kategória'],
    ['description', 'Részletes leírás'],
    ['primaryColor', 'Elsődleges szín'],
    ['secondaryColor', 'Másodlagos szín'],
    ['tertiaryColor', 'Harmadlagos szín'],
    ['deadline', 'Határidő'],
    ['budgetValue', 'Büdzsé']
  ])

  const handleSubmit = (values: any) => {
    const body = {
      EmailAddress: values.emailAddress,
      NewWebsite: values.newWebsite,
      ExistingWebsiteUrl: values.existingWebsiteUrl,
      Category: values.category,
      ColorPalette: {
        PrimaryColor: values.primaryColor,
        SecondaryColor: values.secondaryColor,
        TertiaryColor: values.tertiaryColor
      },
      Description: values.description,
      Deadline: values.deadline,
      Budget: {
        Value: values.budgetValue,
        Currency: 'HUF'
      }
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/design`, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) {
          throw new Error(`Error in request: ${response.status} ${response.statusText}`)
        }
      })
      .then(() => navigate('/success'))
      .catch(() => displayErrorMessage('Valami hiba történt ☹️'))
  }

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      newWebsite: 'true',
      existingWebsiteUrl: '',
      category: "STATIC",
      primaryColor: '#002395',
      secondaryColor: '#ffffff',
      tertiaryColor: '#ff0090',
      description: '',
      deadline: undefined,
      budgetValue: undefined,
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string().email('Hibás formátum!').required('Kötelező mező!'),
      newWebsite: Yup.bool().required('Kötelező mező!'),
      existingWebsiteUrl: Yup.string(),
      category: Yup.string(),
      primaryColor: Yup.string(),
      secondaryColor: Yup.string(),
      tertiaryColor: Yup.string(),
      description: Yup.string(),
      deadline: Yup.date(),
      budgetValue: Yup.number(),
    }),
    onSubmit: handleSubmit
  })

  useEffect(() => {
    formik.validateForm()
    setTimeout(() => setDrawerOpen(true), 100)
  }, [])

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false)
        setTimeout(() => navigate('/'), 100)
      }}
      onOpen={() => {}}
    >
      <div className="p-4 rounded-t-lg right-0 left-0 overflow-hidden">
        <div className="pb-8 max-h-80vh overflow-y-scroll">
          <div className="mx-4">
            <form className="mx-auto max-w-xl sm:mt-20" onSubmit={formik.handleSubmit}>

              <div className="my-12">
                <SelectList
                  title="Új weboldalt szeretnél készíteni vagy meglévő weboldalad szeretnéd felújítani?"
                >
                  <SelectListItem
                    name="newWebsite"
                    value="true"
                    title="Új weboldal"
                    onChange={formik.handleChange}
                    checked={formik.values.newWebsite === 'true'}
                  />
                  <SelectListItem
                    name="newWebsite"
                    value="false"
                    title="Weboldal felújítása"
                    onChange={formik.handleChange}
                    checked={formik.values.newWebsite === 'false'}
                  />
                </SelectList>
                { formik.errors.newWebsite ? <div className="text-red-500 m-3">{formik.errors.newWebsite}</div> : null }

                {
                  formik.values.newWebsite === 'false' ? (
                    <FadeIn animationDuration={500}>
                      <div className="sm:col-span-2 my-6">
                        <label htmlFor="existingWebsiteUrl" className="block text-sm font-semibold leading-6 text-gray-900">Meglévő weboldal címe</label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="existingWebsiteUrl"
                            id="website"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={formik.handleChange}
                            value={formik.values.existingWebsiteUrl}
                          />
                          { formik.errors.existingWebsiteUrl ? <div className="text-red-500 m-3">{formik.errors.existingWebsiteUrl}</div> : null }
                        </div>
                      </div>
                    </FadeIn>
                  ) : null
                }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <WebsiteCategory form={formik} />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <h3 className="mb-5 text-lg font-medium text-gray-900">
                  Van már elképzelésed a színpalettával kapcsolatban?
                </h3>

                <ul className="grid w-full gap-6">
                  <li>
                    <button
                      type="button"
                      className={`inline-flex items-center justify-between w-full p-5 bg-white border rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100 ${hasColorPalette ? 'border-gray-200 text-gray-500' : 'border-blue-600 text-blue-600'}`}
                      onClick={() => setHasColorPalette(false)}
                    >
                      <div className="block">
                        <div className="w-full text-lg font-semibold">Még nincs.</div>
                      </div>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={`inline-flex items-center justify-between w-full p-5 bg-white border rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100 ${hasColorPalette ? 'border-blue-600 text-blue-600' : 'border-gray-200 text-gray-500'}`}
                      onClick={() => setHasColorPalette(true)}
                    >
                      <div className="block">
                        <div className="w-full text-lg font-semibold">Van.</div>
                      </div>
                    </button>
                  </li>
                </ul>

                {
                  hasColorPalette ? (
                    <FadeIn animationDuration={500}>
                      <div className="my-12">
                        <div className="m-5">
                          <ColorPicker
                            title="Elsődleges szín"
                            name="primaryColor"
                            initialValue={formik.initialValues.primaryColor}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="m-5">
                          <ColorPicker
                            title="Másodlagos szín"
                            name="secondaryColor"
                            initialValue={formik.initialValues.secondaryColor}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="m-5">
                          <ColorPicker
                            title="Harmadlagos szín"
                            name="tertiaryColor"
                            initialValue={formik.initialValues.tertiaryColor}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </FadeIn>
                  ) : null
                }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <Description
                  title="Részletes leírás"
                  name="description"
                  form={formik}
                  initialValue=""
                  imagesUploadUrl={`${process.env.REACT_APP_API_ENDPOINT}/image`}
                />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <Contact
                  title="Kapcsolat"
                  name="emailAddress"
                  form={formik}
                  onPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
                />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              {
                formik.isValid ?
                  null :
                  (
                    <div role="alert">
                      <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                        Hibás adatok
                      </div>
                      <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <div>
                          <span className="font-medium">Az alábbi mezők nincsenek helyesen kitöltve:</span>
                          <ul className="mt-1.5 list-disc list-inside">
                            {
                              Object.keys(formik.errors).map((field) => <li key={uuidv4()}>{fieldNameToFieldDisplayName.get(field)}</li>)
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
              }

              {
                showErrorMessage ?
                  (
                    <div role="alert">
                      <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2"></div>
                      <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <div>
                          <span className="font-medium">{errorMessage}</span>
                        </div>
                      </div>
                    </div>
                  ) : null
              }

              <div className="my-12">
                <Button type="submit" disabled={!formik.isValid || !privacyPolicyAccepted}>Elküld</Button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  )
}
