import AgreeToPrivacyPolicy from './AgreeToPrivacyPolicy'

interface ContactProps {
  title: string
  name: string
  form: any
  onPrivacyPolicyAccepted: any
}

export default function Contact(props: Readonly<ContactProps>) {
  return (
    <>
      <h3 className="mb-5 text-lg font-medium text-gray-900">
        {props.title}
      </h3>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
          <p>Az itt megadott email címen fogunk visszajelezni.</p>
          <div className="mt-2.5">
            <input
              type="email"
              name={props.name}
              id="email"
              placeholder="te@emailcímed.com"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={props.form.handleChange}
              value={props.form.values[props.name]}
            />
            { props.form.errors[props.name] ? <div className="text-red-500 m-3">{props.form.errors[props.name]}</div> : null }
          </div>
        </div>

        <AgreeToPrivacyPolicy onAgree={props.onPrivacyPolicyAccepted} />
      </div>
    </>
  )
}
