import { useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, DialogActions } from '@mui/material'

interface ColorPickerProps {
  title: string
  name: string
  onChange: any
  initialValue: any
}

export default function ColorPicker(props: Readonly<ColorPickerProps>) {
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [value, setValue] = useState(props.initialValue)

  const handleChange = (color: ColorResult) => {
    setValue(color.hex)
    props.onChange({target: {name: props.name, value: color.hex}})
  }

  return (
    <>
      <div className="grid gap-6 grid-cols-2">
        <h4 className="text-center block text-sm font-semibold leading-6 text-gray-900">
          {props.title}
        </h4>
        <button
          type="button"
          className="bg-slate-800 w-12 h-8 rounded border-black border-2"
          style={{ background: value }}
          onClick={() => setShowColorPicker(true)}
        />
      </div>
      <Dialog open={showColorPicker} onClose={() => setShowColorPicker(false)}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <SketchPicker
            color={value}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowColorPicker(false)}>Mégsem</Button>
          <Button onClick={() => setShowColorPicker(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
