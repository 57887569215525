interface FeatureCarouselItemProps {
  text: string
}

export default function FeatureCarouselItem(
  props: Readonly<FeatureCarouselItemProps>
) {
  return (
    <p
      className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
    >
      {props.text}
    </p>
  )
}
