interface SelectListItemProps {
  name: string
  value: any
  defaultChecked?: boolean
  checked?: boolean
  disabled?: boolean
  title: string
  description?: string
  onChange?: any
}

export default function SelectListItem(props: Readonly<SelectListItemProps>) {
  const id = `${props.name}-${props.value}`

  return (
    <>
      <input
        type="radio"
        id={id}
        name={props.name}
        value={props.value}
        className="hidden peer"
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <label
        htmlFor={id}
        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
      >
        <div className="block">
          <div className="w-full text-lg font-semibold">{props.title}</div>
          {
            props.description ? <div className="w-full">{props.description}</div> : null
          }
        </div>
      </label>
    </>
  )
}
