import { useState } from 'react'
import AgreeToPrivacyPolicy from './AgreeToPrivacyPolicy'
import Button from './Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

export default function MessageForm() {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)

  const navigate = useNavigate()

  const displayErrorMessage = (message: string) => {
    setErrorMessage(message)
    setShowErrorMessage(true)
  }

  const handleSubmit = (values: any) => {
    const body = {
      EmailAddress: values.emailAddress,
      Message: values.message
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/message`, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) {
          throw new Error(`Error in request: ${response.status} ${response.statusText}`)
        }
      })
      .then(() => navigate('/success'))
      .catch(() => displayErrorMessage('Valami hiba történt ☹️'))
  }

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      message: ''
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string().email('Hibás formátum!').required('Kötelező mező!'),
      message: Yup.string().required('Kötelező mező!')
    }),
    onSubmit: handleSubmit
  })

  return (
    <div className="isolate bg-white px-6 lg:px-8">
      <form className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
            <p>Az itt megadott email címen fogunk visszajelezni.</p>
            <div className="mt-2.5">
              <input
                type="email"
                name="emailAddress"
                id="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
              />
            </div>
            { formik.errors.emailAddress ? <div className="text-red-500 m-3">{formik.errors.emailAddress}</div> : null }
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">Üzenet</label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                value={formik.values.message}
              />
              { formik.errors.message ? <div className="text-red-500 m-3">{formik.errors.message}</div> : null }
            </div>
          </div>

          <AgreeToPrivacyPolicy onAgree={setPrivacyPolicyAccepted} />
        </div>

        {
          showErrorMessage ?
            (
              <div role="alert" className="my-12">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2"></div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  <div>
                    <span className="font-medium">{errorMessage}</span>
                  </div>
                </div>
              </div>
            ) : null
        }

        <div className="mt-10">
          <Button type="submit" disabled={!formik.isValid || !privacyPolicyAccepted}>Elküld</Button>
        </div>
      </form>
    </div>
  )
}
