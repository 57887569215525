import SelectList from './components/SelectList'
import SelectListItem from './components/SelectListItem'
import Button from './components/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import WebsiteCategory from './components/WebsiteCategory'
import Description from './components/Description'
import Contact from './components/Contact'
import { useNavigate } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'

export default function HostingForm() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()

  const displayErrorMessage = (message: string) => {
    setErrorMessage(message)
    setShowErrorMessage(true)
  }

  const fieldNameToFieldDisplayName = new Map([
    ['emailAddress', 'Email'],
    ['externalApp', 'Nálunk készített weboldalt hosztolnál?'],
    ['existingWebsiteUrl', 'Domain név (tervezett domain név, ha még nincs)'],
    ['category', 'Kategória'],
    ['description', 'Részletes leírás'],
    ['domain', 'Domain'],
    ['ssl', 'SSL'],
    ['targetAudience', 'Célközönség'],
    ['estimatedDau', 'Várható napi aktív felhasználók száma'],
    ['securityPackage', 'Biztonság'],
    ['backupRetention', 'Visszaállítási pontok (backupok) megőrzése'],
    ['supportPackage', 'Szupport'],
    ['deadline', 'Határidő'],
    ['budgetValue', 'Büdzsé']
  ])

  const handleSubmit = (values: any) => {
    const body = {
      EmailAddress: values.emailAddress,
      ExternalApp: values.externalApp,
      ExistingWebsiteUrl: values.existingWebsiteUrl,
      Category: values.category,
      Description: values.description,
      Domain: values.domain,
      Ssl: values.ssl,
      TargetAudience: values.targetAudience,
      EstimatedDau: values.estimatedDau,
      SecurityPackage: values.securityPackage,
      BackupRetention: values.backupRetention,
      SupportPackage: values.supportPackage,
      Deadline: values.deadline,
      Budget: {
        Value: values.budgetValue,
        Currency: 'HUF'
      }
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/hosting`, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) {
          throw new Error(`Error in request: ${response.status} ${response.statusText}`)
        }
      })
      .then(() => navigate('/success'))
      .catch(() => displayErrorMessage('Valami hiba történt ☹️'))
  }

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      externalApp: undefined,
      existingWebsiteUrl: '',
      category: 'STATIC',
      description: '',
      domain: 'MANAGED',
      ssl: 'MANAGED',
      targetAudience: 'HUNGARY',
      estimatedDau: '',
      securityPackage: 'STANDARD',
      backupRetention: 'STANDARD',
      supportPackage: 'AD_HOC',
      deadline: undefined,
      budgetValue: undefined
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string().email('Hibás formátum!').required('Kötelező mező!'),
      externalApp: Yup.bool().required('Kötelező mező!'),
      existingWebsiteUrl: Yup.string(),
      category: Yup.string(),
      description: Yup.string(),
      domain: Yup.string(),
      ssl: Yup.string(),
      targetAudience: Yup.string(),
      estimatedDau: Yup.string().required('Kötelező mező!'),
      securityPackage: Yup.string(),
      backupRetention: Yup.string(),
      supportPackage: Yup.string(),
      deadline: Yup.date(),
      budgetValue: Yup.number(),
    }),
    onSubmit: handleSubmit
  })

  useEffect(() => {
    formik.validateForm()
    setTimeout(() => setDrawerOpen(true), 100)
  }, [])

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false)
        setTimeout(() => navigate('/'), 100)
      }}
      onOpen={() => {}}
    >
      <div className="p-4 rounded-t-lg right-0 left-0 overflow-hidden">
        <div className="pb-8 max-h-80vh overflow-y-scroll">
          <div className="mx-4">
            <form className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={formik.handleSubmit}>

              <div className="my-12">
                <SelectList title="Nálunk készített weboldalt hosztolnál?">
                  <SelectListItem
                    name="externalApp"
                    value="true"
                    title="Igen"
                    onChange={formik.handleChange}
                    checked={formik.values.externalApp === 'true'}
                  />
                  <SelectListItem
                    name="externalApp"
                    value="false"
                    title="Nem"
                    onChange={formik.handleChange}
                    checked={formik.values.externalApp === 'false'}
                  />
                </SelectList>
                { formik.errors.externalApp ? <div className="text-red-500 m-3">{formik.errors.externalApp}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <WebsiteCategory form={formik} />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <Description
                  title="Részletes leírás"
                  name="description"
                  form={formik}
                  initialValue=""
                  imagesUploadUrl={`${process.env.REACT_APP_API_ENDPOINT}/image`}
                />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList title="Domain név">
                  <SelectListItem
                    name="domain"
                    value="MANAGED"
                    title="Menedzselt"
                    description="A domain név elérhetőségét ellenőrizzük. Ha elérhető megvásároljuk, és automatikusan megújítjuk."
                    onChange={formik.handleChange}
                    checked={formik.values.domain === 'MANAGED'}
                  />
                  <SelectListItem
                    name="domain"
                    value="SELF_MANAGED"
                    title="Önmenedzselt"
                    description="A domain név elérhetőségének ellenőrzése, megvásárlása, és megújítása a te feladatod."
                    onChange={formik.handleChange}
                    checked={formik.values.domain === 'SELF_MANAGED'}
                  />
                </SelectList>
                { formik.errors.domain ? <div className="text-red-500 m-3">{formik.errors.domain}</div> : null }

                <div className="sm:col-span-2 my-6">
                  <label htmlFor="domain-name" className="block text-sm font-semibold leading-6 text-gray-900">
                    Domain név (tervezett domain név, ha még nincs)
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="existingWebsiteUrl"
                      id="domain-name"
                      placeholder="weboldalam.com"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={formik.handleChange}
                      value={formik.values.existingWebsiteUrl}
                    />
                    { formik.errors.existingWebsiteUrl ? <div className="text-red-500 m-3">{formik.errors.existingWebsiteUrl}</div> : null }
                  </div>
                </div>
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList
                  title="SSL"
                  description="Ahhoz, hogy a kliens biztonságos kapcsolatot létesíthessen a szerverrel,
                  SSL tanúsítványra van szükség. Egyes böngészők nem engedik a kapcsolódást,
                  vagy figyelmeztető üzenetet jelenítenek meg, ami csökkentheti a weboldal
                  forgalmát. Ezen intézkedések azonban indokoltak, mivel
                  amennyiben nem lehet titkosított kapcsolatot létrehozni, szenzitív
                  információk kerülhetnek illetéktelenek kezébe."
                >
                  <SelectListItem
                    name="ssl"
                    value="MANAGED"
                    title="Menedzselt"
                    description="Az SSL tanúsítvány megszerzését, rotálását, és frissítését automatikusan intézzük."
                    onChange={formik.handleChange}
                    checked={formik.values.ssl === 'MANAGED'}
                  />
                  <SelectListItem
                    name="ssl"
                    value="SELF_MANAGED"
                    title="Önmenedzselt"
                    description="Ebben az esetben az SSL tanúsítvány megszerzése és rotálása a te feladatod.
                    Mi frissítjük a tanúsítványt miután megosztottad velünk. A frissítés
                    a választott szupport csomagodtól függően kerül teljesítésre."
                    onChange={formik.handleChange}
                    checked={formik.values.ssl === 'SELF_MANAGED'}
                  />
                </SelectList>
                { formik.errors.ssl ? <div className="text-red-500 m-3">{formik.errors.ssl}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList title="Célközönség">
                  <SelectListItem
                    name="targetAudience"
                    value="HUNGARY"
                    title="Magyarország"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'HUNGARY'}
                  />
                  <SelectListItem
                    name="targetAudience"
                    value="EUROPE"
                    title="Európa"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'EUROPE'}
                  />
                  <SelectListItem
                    name="targetAudience"
                    value="WORLDWIDE"
                    title="Egész világ"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'WORLDWIDE'}
                  />
                  <SelectListItem
                    name="targetAudience"
                    value="AMERICAS"
                    title="Amerika"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'AMERICAS'}
                  />
                  <SelectListItem
                    name="targetAudience"
                    value="ASIA"
                    title="Ázsia & Óceánia"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'ASIA'}
                  />
                  <SelectListItem
                    name="targetAudience"
                    value="AFRICA"
                    title="Afrika"
                    onChange={formik.handleChange}
                    checked={formik.values.targetAudience === 'AFRICA'}
                  />
                </SelectList>
                { formik.errors.targetAudience ? <div className="text-red-500 m-3">{formik.errors.targetAudience}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />
              
              <div className="my-12">
                <SelectList title="Várható napi aktív felhasználók száma">
                  <SelectListItem
                    name="estimatedDau"
                    value="DOZENS"
                    title="1-99"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'DOZENS'}
                  />
                  <SelectListItem
                    name="estimatedDau"
                    value="HUNDREDS"
                    title="100-1k"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'HUNDREDS'}
                  />
                  <SelectListItem
                    name="estimatedDau"
                    value="THOUSANDS"
                    title="1k-10k"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'THOUSANDS'}
                  />
                  <SelectListItem
                    name="estimatedDau"
                    value="TEN_THOUSANDS"
                    title="10k-100k"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'TEN_THOUSANDS'}
                  />
                  <SelectListItem
                    name="estimatedDau"
                    value="HUNDRED_THOUSANDS"
                    title="100k-1M"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'HUNDRED_THOUSANDS'}
                  />
                  <SelectListItem
                    name="estimatedDau"
                    value="MILLIONS"
                    title="1M+"
                    onChange={formik.handleChange}
                    checked={formik.values.estimatedDau === 'MILLIONS'}
                  />
                </SelectList>
                { formik.errors.estimatedDau ? <div className="text-red-500 m-3">{formik.errors.estimatedDau}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList title="Biztonság">
                  <SelectListItem
                    name="securityPackage"
                    value="STANDARD"
                    title="Standard"
                    description="Az infrastruktúra biztonsága a mi feladatunk, a webapplikáció biztonsága a tied."
                    onChange={formik.handleChange}
                    checked={formik.values.securityPackage === 'STANDARD'}
                  />
                  <SelectListItem
                    name="securityPackage"
                    value="REPORT"
                    title="Értesítés applikáció sérülékenységekről"
                    description="Rendszeresen szkenneljük a webapplikációt, és amennyiben sérülékenységet találunk, arról értesítünk téged."
                    onChange={formik.handleChange}
                    checked={formik.values.securityPackage === 'REPORT'}
                  />
                  <SelectListItem
                    name="securityPackage"
                    value="FIX"
                    title="Applikáció sérülékenységek ASAP javítása"
                    description="Rendszeresen szkenneljük a webapplikációt, és amennyiben sérülékenységet találunk, javítjuk. Ez az opció csak akkor érhető el, ha a webapplikációt is nálunk készítetted."
                    onChange={formik.handleChange}
                    checked={formik.values.securityPackage === 'FIX'}
                  />
                </SelectList>
                { formik.errors.securityPackage ? <div className="text-red-500 m-3">{formik.errors.securityPackage}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList
                  title="Visszaállítási pontok (backupok) megőrzése"
                  description="A webalkalmazás állapotáról rendszeresen backupot / visszaállítási pontot
                  készítünk. Erre azért van szükség, hogy az applikáció vagy az infrastruktúra
                  súlyos meghibásodása esetén vissza lehessen állni a lehető legfrissebb
                  működőképes állapotra. Egyes szektorokban törvény is előírhatja, hogy milyen
                  hosszútávon kell historikus adatokat megőrizni, így ezt is tarts észben
                  mikor kiválasztod a számodra legmegfelelőbb opciót."
                >
                  <SelectListItem
                    name="backupRetention"
                    value="STANDARD"
                    title="Standard"
                    onChange={formik.handleChange}
                    checked={formik.values.backupRetention === 'STANDARD'}
                  />
                  <SelectListItem
                    name="backupRetention"
                    value="LONG_TERM"
                    title="Hosszútávú (1 év)"
                    onChange={formik.handleChange}
                    checked={formik.values.backupRetention === 'LONG_TERM'}
                  />
                  <SelectListItem
                    name="backupRetention"
                    value="EXTRA_LONG_TERM"
                    title="Extra hosszútávú (1+ év)"
                    onChange={formik.handleChange}
                    checked={formik.values.backupRetention === 'EXTRA_LONG_TERM'}
                  />
                </SelectList>
                { formik.errors.backupRetention ? <div className="text-red-500 m-3">{formik.errors.backupRetention}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <SelectList title="Szupport">
                  <SelectListItem
                    name="supportPackage"
                    value="AD_HOC"
                    title="Ad hoc"
                    description="1 héten belül megkezdjük a kérés kivizsgálását. Kérésenként számlát állítunk ki."
                    onChange={formik.handleChange}
                    checked={formik.values.supportPackage === 'AD_HOC'}
                  />
                  <SelectListItem
                    name="supportPackage"
                    value="STANDARD"
                    title="Standard"
                    description="72 órán belül megkezdjük a kérés kivizsgálását. Havidíjat számítunk fel."
                    onChange={formik.handleChange}
                    checked={formik.values.supportPackage === 'STANDARD'}
                  />
                  <SelectListItem
                    name="supportPackage"
                    value="ASAP"
                    title="ASAP"
                    description="Kérésed kivizsgálását azonnal megkezdjük. Havidíjat számítunk fel."
                    onChange={formik.handleChange}
                    checked={formik.values.supportPackage === 'ASAP'}
                  />
                </SelectList>
                { formik.errors.supportPackage ? <div className="text-red-500 m-3">{formik.errors.supportPackage}</div> : null }
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              <div className="my-12">
                <Contact
                  title="Kapcsolat"
                  name="emailAddress"
                  form={formik}
                  onPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
                />
              </div>

              <hr className="h-px my-8 bg-gray-200 border-0" />

              {
                formik.isValid ?
                  null :
                  (
                    <div role="alert">
                      <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                        Hibás adatok
                      </div>
                      <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <div>
                          <span className="font-medium">Az alábbi mezők nincsenek helyesen kitöltve:</span>
                          <ul className="mt-1.5 list-disc list-inside">
                            {
                              Object.keys(formik.errors).map((field) => <li key={uuidv4()}>{fieldNameToFieldDisplayName.get(field)}</li>)
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
              }

              {
                showErrorMessage ?
                  (
                    <div role="alert">
                      <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2"></div>
                      <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <div>
                          <span className="font-medium">{errorMessage}</span>
                        </div>
                      </div>
                    </div>
                  ) : null
              }

              <div className="my-12">
                <Button type="submit" disabled={!formik.isValid || !privacyPolicyAccepted}>Elküld</Button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  )
}
