import SelectList from './SelectList'
import SelectListItem from './SelectListItem'

interface WebsiteCategoryProps {
  form: any
}

export default function WebsiteCategory(props: Readonly<WebsiteCategoryProps>) {
  return (
    <>
      <SelectList title="Kategória">
        <SelectListItem
          name="category"
          value="STATIC"
          title="Statikus"
          description="Statikus weboldal alatt olyan weboldalt értünk, amelynek a tartalma nem
          vagy csak nagyon ritkán változik. Pl. e-vite, termék promóciós oldal, landing page, stb."
          onChange={props.form.handleChange}
          checked={props.form.values.category === 'STATIC'}
        />
        <SelectListItem
          name="category"
          value="SEMI_DYNAMIC"
          title="Dinamikus (zárt)"
          description="Dinamikus weboldalnak nevezzük az olyan oldalakat, amelyek tartalma rendszeresen változik.
          Zártság alatt azt értjük, hogy a fő tartalomgyártók köre egy zárt csoport (adminok, szerkesztőség).
          Pl. webshop, híroldal, blog, stb."
          onChange={props.form.handleChange}
          checked={props.form.values.category === 'SEMI_DYNAMIC'}
        />
        <SelectListItem
          name="category"
          value="DYNAMIC"
          title="Dinamikus (nyílt)"
          description="Dinamikus weboldalnak nevezzük az olyan oldalakat, amelyek tartalma rendszeresen változik.
          Nyíltság alatt azt értjük, hogy a felhasználók felelősek a tartalomgyártásért is.
          Pl. közösségi oldal, társkereső, stb."
          onChange={props.form.handleChange}
          checked={props.form.values.category === 'DYNAMIC'}
        />
      </SelectList>
      { props.form.errors.category ? <div className="text-red-500 m-3">{props.form.errors.category}</div> : null }
    </>
  )
}
