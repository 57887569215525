import { useRef } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from './logo.png'
import logoBlack from './logoBlack.png'
import webdesign from './webdesign.jpg'

export default function App() {
  const menuRef = useRef<HTMLDialogElement>(null)
  const location = useLocation()

  function showMenu() {
    menuRef.current?.showModal()
  }

  function closeMenu() {
    menuRef.current?.close()
  }

  return (
    <div className="bg-white">
      <header
        className="absolute inset-x-0 top-0 z-50 bg-auto lg:bg-cover bg-no-repeat"
        style={{backgroundImage: `url(${webdesign})`}}
      >
        {
          (
            location.pathname === '/' ||
            location.pathname === '/design' ||
            location.pathname === '/hosting'
          ) ? (
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5 p-1.5 flex items-center">
                  <img src={logo} className="h-8 w-8 mx-2" alt="logo"></img>
                  <span className="text-white font-bold">KIBERMŰHELY</span>
                </Link>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => {showMenu()}}
                >
                  <span className="sr-only">Menü megnyitása</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12 text-white">
                <a href="/#webdesign" className="text-sm font-semibold leading-6 group transition duration-300 drop-shadow-[0px_2px_rgba(0,0,0,0.8)] underline underline-offset-4 decoration-pink-500 decoration-4 hover:no-underline">
                  Webdizájn<span className="-mt-1 block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
                </a>
                <a href="/#webhosting" className="text-sm font-semibold leading-6 group transition duration-300 drop-shadow-[0px_2px_rgba(0,0,0,0.8)] underline underline-offset-4 decoration-pink-500 decoration-4 hover:no-underline">
                  Webhoszting<span className="-mt-1 block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
                </a>
                <a href="/#testimonials" className="text-sm font-semibold leading-6 group transition duration-300 drop-shadow-[0px_2px_rgba(0,0,0,0.8)] underline underline-offset-4 decoration-pink-500 decoration-4 hover:no-underline">
                  Rólunk mondták<span className="-mt-1 block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
                </a>
                <a href="/#contact" className="text-sm font-semibold leading-6 group transition duration-300 drop-shadow-[0px_2px_rgba(0,0,0,0.8)] underline underline-offset-4 decoration-pink-500 decoration-4 hover:no-underline">
                  Kapcsolat<span className="-mt-1 block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-indigo-600"></span>
                </a>
              </div>
              <dialog ref={menuRef} className='lg:hidden' aria-modal="true">
                <div className="fixed inset-0 z-50"></div>
                <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                  <div className="flex items-center justify-between">
                    <Link to="/" className="-m-1.5 p-1.5 flex items-center">
                      <img src={logoBlack} className="h-8 w-8 mx-2" alt="logo"></img>
                      <span className="text-black font-bold">KIBERMŰHELY</span>
                    </Link>
                    <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => closeMenu()}>
                      <span className="sr-only">Menü bezárása</span>
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="space-y-2 py-6">
                        <a
                          href="/#webdesign"
                          onClick={() => closeMenu()}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Webdizájn
                        </a>
                        <a
                          href="/#webhosting"
                          onClick={() => closeMenu()}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Webhoszting
                        </a>
                        <a
                          href="/#testimonials"
                          onClick={() => closeMenu()}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Rólunk mondták
                        </a>
                        <a
                          href="/#contact"
                          onClick={() => closeMenu()}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Kapcsolat
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </dialog>
            </nav>
          ) : (
            <nav className="flex items-center justify-center p-6 lg:px-8" aria-label="Global">
              <div className="flex">
                <Link to="/" className="-m-1.5 p-1.5 flex items-center">
                  <img src={logo} className="h-8 w-8 mx-2" alt="logo"></img>
                  <span className="text-white font-bold">KIBERMŰHELY</span>
                </Link>
              </div>
            </nav>
          )
        }
      </header>

      <main className="min-h-fill">
        <Outlet />
      </main>
      
      <footer className="bg-black h-64 flex flex-col">
        <div className="text-white grow flex items-center justify-center">
          <Link to="/" className="flex items-center">
            <img src={logo} className="h-8 w-8 mx-2" alt="logo"></img>
            <span className="self-center text-2xl font-semibold whitespace-nowrap">KIBERMŰHELY</span>
          </Link>
        </div>
        <hr className="h-px mx-8 bg-white border-0" />
        <div className="m-8 flex flex-col items-center justify-between">
          <Link to="/privacy-policy" className="mx-2 text-white hover:text-sky-400">[Adatvédelmi tájékoztató]</Link>
          <Link to="/cookie-policy" className="mx-2 text-white hover:text-sky-400">[Süti tájékoztató]</Link>
          <Link to="/impressum" className="mx-2 text-white hover:text-sky-400">[Impresszum]</Link>
        </div>
      </footer>
    </div>
  )
}
