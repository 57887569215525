import { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface SelectListProps {
  title: string
  description?: string
  children: ReactNode[]
}

export default function SelectList(props: Readonly<SelectListProps>) {
  return (
    <div>
      <h3 className="mb-5 text-lg font-medium text-gray-900">{props.title}</h3>
      {
        props.description ?
          (
            <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md my-8" role="alert">
              <div className="flex">
                <div className="py-1">
                  <svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                  </svg>
                </div>
                <div>
                  <p className="text-sm">{props.description}</p>
                </div>
              </div>
            </div>
          ) :
          null
      }
      <ul className="grid w-full gap-6">
        {
            props.children.map((x: any) => {
            return (
              <li key={uuidv4()}>
                {x}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
