import { Link } from 'react-router-dom'
import webdesign from './webdesign.jpg'

export default function SuccessfulRequest() {
  return (
    <div
      className="relative isolate flex items-center justify-center min-h-fill bg-auto lg:bg-cover bg-no-repeat"
      style={{backgroundImage: `url(${webdesign})`}}
    >
      <div className="rounded overflow-hidden shadow-lg bg-white p-14 text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Kérésedet sikeresen befogadtuk 😊</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Kollegáink hamarosan felveszik veled a kapcsolatot.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to="/" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Vissza a főoldalra
          </Link>
        </div>
      </div>
    </div>
  )
}
