import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import HostingForm from './HostingForm'
import DesignForm from './DesignForm'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import ErrorPage from './ErrorPage'
import Home from './Home'
import SuccessfulRequest from './SuccessfulRequest'
import PrivacyPolicy from './PrivacyPolicy'
import CookiePolicy from './CookiePolicy'
import Impressum from './Impressum'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Home />,
        children: [
          { path: 'design', element: <DesignForm /> },
          { path: 'hosting', element: <HostingForm /> }
        ]
      },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'cookie-policy', element: <CookiePolicy /> },
      { path: 'impressum', element: <Impressum /> },
      { path: 'success', element: <SuccessfulRequest /> },
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

