interface ButtonProps {
  type?: 'submit' | 'button'
  disabled?: boolean
  children: string
}

export default function Button(props: Readonly<ButtonProps>) {
  return (
    <button
      type={props.type ?? 'button'}
      disabled={props.disabled}
      className="block w-full rounded-md bg-sky-400 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-slate-400 disabled:hover:bg-slate-500">
      {props.children}
    </button>
  )
}
