import { Switch } from '@headlessui/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

interface AgreeToPrivacyPolicyProps {
  onAgree?: (result: boolean) => any
}

export default function AgreeToPrivacyPolicy(props: Readonly<AgreeToPrivacyPolicyProps>) {
  const [agreed, setAgreed] = useState(false)

  const handleClick = () => {
    const result = !agreed
    setAgreed(result)
    if (props.onAgree) {
      props.onAgree(result)
    }
  }

  return (
    <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
      <div className="flex h-6 items-center">
        <Switch
          checked={agreed}
          onChange={() => handleClick()}
          className={`${agreed ? 'bg-indigo-600' : 'bg-gray-200'} flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          <span className="sr-only">Adatvédelmi tájékoztató elfogadása</span>
          <span
            aria-hidden="true"
            className={`${agreed ? 'translate-x-3.5' : 'translate-x-0'} h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <Switch.Label className="text-sm leading-6 text-gray-600">
        Elfogadom az <Link to="/privacy-policy" className="font-semibold text-sky-400 hover:text-indigo-600">Adatvédelmi Tájékoztató</Link>ban foglaltakat.
      </Switch.Label>
    </Switch.Group>
  )
}
