import { animated, useSpringRef, useTransition } from '@react-spring/web'
import FeatureCarouselItem from './FeatureCarouselItem'
import { useEffect, useState } from 'react'

interface FeatureCarouselProps {
  features: string[]
  constantText: string
  slideDuration: number
}

export default function FeatureCarousel(
  props: Readonly<FeatureCarouselProps>
) {
  const [index, setIndex] = useState(0)

  const transRef = useSpringRef()

  const transitions = useTransition(index, {
    ref: transRef,
    from: { opacity: 0, transform: 'translate3d(0%,-100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0%,0)' },
  })

  useEffect(() => {
    transRef.start()
    const timer = setInterval(() => {
      setIndex((index + 1) % props.features.length)
    }, props.slideDuration)
    return () => {
      window.clearInterval(timer)
    }
  }, [index, setIndex])

  return (
    <div className="p-4 flex flex-col items-center justify-around">
      <div className="text-center w-full">
        <div className="overflow-hidden relative min-w-full h-14 sm:h-15">
          {transitions((style, i) => (
            <animated.div className="absolute w-full text-center" style={{...style}}>
              <FeatureCarouselItem
                key={`feature-${i}`}
                text={props.features[i]}
              />
            </animated.div>
          ))}
        </div>
        <p className="m-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {props.constantText}
        </p>
      </div>
    </div>
  )
}
